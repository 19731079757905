import React from 'react';
import { Link } from 'react-router-dom'
import Logo from "../../../assets/images/dkgcrmlogo.png";
import './style.scss';
import { useSelector } from "react-redux";
import LoginFaqModal from '../../pages/login/faq/LoginFaq'

const RecuiterHeader = (props) => {
    const [showAlert, setShowAlert] = React.useState(false)
    const[isLogin, setIsLogin] = React.useState(false);

    const showShowAlert = () => {
        setShowAlert(true)
    }
    const showloginModal = () =>{
        setIsLogin(true);
    }
    const hideloginModal = () =>{
        setIsLogin(false);
    }
    const handleExternalLink = () => {
        window.location.href = 'https://dkglobalcrm.com/';
    }
    return (
        <React.Fragment>
            {
                (isLogin) ? <LoginFaqModal onHide={hideloginModal} /> : null
            }
            <div className={`dk-mdHeaderMain dk-dkgcrm-header dkg-crm-recruiterHeader ${props.dkgmainheaader}`} style={{height: "52px"}}>
                <div className="d-flex align-items-center justify-content-between dk-mbtopheaderCon">
                    <div className="dk-moduleLogo ">
                        <Link to="#"  className="dkg-crm-cnacc-logoCon" onClick={handleExternalLink}>
                             <img src={Logo} className="img-fluid" alt="" />
                        </Link>
                    </div>
                    <div className="headerTitle" style={{ color: "#5a4c72" }}>
                    {/* PARTNER ACCOUNT */}
                    </div>
                    <div className="dk-mdHeader-rightPanel">
                         <div className="dkg-login-faq-btnCon">
                            <button className='dkg-login-faq-btn btn' onClick={showloginModal}>
                            Login FAQ's <span className='dkg-login-info-icon'>
                                {/* <FaInfoCircle  /> */}
                            </span>
                            </button>
                         </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}

export default RecuiterHeader;