import React from "react";
import "./App.css";
import { Switch, Route } from "react-router-dom";
import "@fortawesome/fontawesome-free/css/all.min.css";
import "bootstrap-css-only/css/bootstrap.min.css";
import "mdbreact/dist/css/mdb.css";
import LoginRoute from "./components/hocs/LoginRoute";
import MainPrivateRoute from "./components/hocs/MainPrivateRoute";
import PrivateRoute from "./components/hocs/PrivateRoute";
import MyaccountRoute from "./components/hocs/MyaccountRoute";
import Login from "./components/pages/login";
import PartnerLogin from "./components/pages/login/PartnerLogin";


// Start My Account Module
import MyaccountDashboard from "./components/pages/myaccount/dashboard";
import MyaccountMailbox from "./components/pages/myaccount/mailbox/MyaccMailbox";
import MyaccountProfile from "./components/pages/myaccount/myprofile/MyProfile";
import MyaccResetLogin from "./components/pages/myaccount/resetlogin/ChangeLogin";
import MyaccDeleteAcc from "./components/pages/myaccount/setting/delete-acc/DeleteAccount";
import MyacctNotifiation from "./components/pages/myaccount/setting/notification/AccountNotifiation";
import MyaccContact from "./components/pages/myaccount/setting/contacts/MyaccContact";
import MyaccFeedback from "./components/pages/myaccount/setting/feedback/MyaccFeedback";
import MyaccCookies from "./components/pages/myaccount/setting/cookies/MyaccCookies";
import MyaccTestTable from "./components/pages/myaccount/table/TestTable";
import MyaccPrivacy from "./components/pages/myaccount/privacy/MyaccPrivacy";
import MyaccTermsofUse from "./components/pages/myaccount/setting/terms/TermsofUse";
import MyaccMyfiles from "./components/pages/myaccount/setting/myfile/Myfiles";
import MyaccGdpr from "./components/pages/myaccount/privacy/GdprPrivacy";
// import MyaccServicesSummary from "./components/pages/myaccount/setting/overview/ServicesOverview";
import MyaccServicesOverview from "./components/pages/myaccount/setting/overview/ServicesOverview";
import MyaccServicesConsults from "./components/pages/myaccount/services/consult/Consults";

import MyaccServicespricing from './components/pages/myaccount/services/pricing/Paidserviceprice'
import MyaccServicestestiomonial from './components/pages/myaccount/services/testiomonial'
import MyaccServicesfeedbacks from './components/pages/myaccount/services/feedbacks'
import MyaccServicesfaq from './components/pages/myaccount/services/faq/ServiceFaq'

import CommingsoonPage from "./components/pages/myaccount/commingsoon/CommingPage";
import MyaccBenfitService from "./components/pages/myaccount/services/benfits/BenfitService";

import MyaccCandidates from "./components/pages/myaccount/facilities/Candidates";
import MyaccCallSms from "./components/pages/myaccount/facilities/CallSms";
import MyaccCRMLinesense from "./components/pages/myaccount/facilities/CRMLinesense";
import MyaccMSoffice from "./components/pages/myaccount/facilities/MSoffice";
import MyaccMSMailbox from "./components/pages/myaccount/facilities/MSMailbox";
import MyaccJobProfile from "./components/pages/myaccount/job-profile/JobProfile";
import MyaccPayment from "./components/pages/myaccount/payments/Payment";
import MyaccInvoice from "./components/pages/myaccount/invoices/Invoice";
import MyaccContract from "./components/pages/myaccount/contracts/Contract";
import MyaccFAQ from "./components/pages/myaccount/faq/Faq";
import MyaccKpi from "./components/pages/myaccount/kpi/MyaccKpi";
import MyaccInterview from "./components/pages/myaccount/interviews/MyaccInterview";
import MyaccJoboffer from "./components/pages/myaccount/joboffer/MyaccJoboffer";
import MyaccReferrals from "./components/pages/myaccount/referral/Referrals";

import MyaccMyresume from "./components/pages/myaccount/myresume/MyaccMyresume";
import MyaccLeaves from "./components/pages/myaccount/leaves/MyaccLeaves";
import MyaccProfileSuMM from "./components/pages/myaccount/myprofile/MyProfile";
import MyaccProfileAccInfo from "./components/pages/myaccount/myprofile/acc-info/AccountInfo";
import MyaccProfileContactInfo from "./components/pages/myaccount/myprofile/contact-info/ContactInfo";

import MyaccProfileContact from "./components/pages/myaccount/myprofile/contact/MyaccContact";
import MyaccResume from "./components/pages/myaccount/myprofile/resume/MyaccResume";
import MyaccCoverLetter from "./components/pages/myaccount/myprofile/cover/CoverLetter";
import MyaccUploadCv from "./components/pages/myaccount/myprofile/uploadcv/UploadCv";
import MyaccDownloadCv from "./components/pages/myaccount/myprofile/downloadcv/DownloadCv";

import MyaccMyProfile from "./components/pages/myaccount/myprofile/profile/MyProfile";
import MyaccPaidservover from "./components/pages/myaccount/paidservice/overview/Paidserveoverview";


import MyaccDocuments from "./components/pages/myaccount/myprofile/documents/Document";
import MyaccMyReferrals from "./components/pages/myaccount/myprofile/my-referrals/MyReferrals";

// import MyaccMyresume from './components/pages/myaccount/holidays/Holidays';
//End My Account Module

function App() {
  return (
    <div className="App">
      <Switch>
        {/* <LoginRoute exact path="/" component={Login} /> */}
        <LoginRoute exact path="/" component={PartnerLogin} />
        <MyaccountRoute
          path="/js-account/mailbox"
          component={MyaccountMailbox}
        />
        <MyaccountRoute
          path="/dashboard"
          component={MyaccountDashboard}
        />
        <MyaccountRoute
          path="/js-account/profile"
          component={MyaccountProfile}
        />
        <MyaccountRoute
          path="/js-account/test-table"
          component={MyaccTestTable}
        />
        <MyaccountRoute
          path="/js-account/settings/privacy"
          component={MyaccPrivacy}
        />
        <MyaccountRoute
          path="/js-account/settings/terms-of-use"
          component={MyaccTermsofUse}
        />
        <MyaccountRoute
          path="/js-account/settings/my-files"
          component={MyaccMyfiles}
        />
        <MyaccountRoute
          path="/js-account/settings/gdpr-explained"
          component={MyaccGdpr}
        />
        {/* <MyaccountRoute path="/js-account/emp-model/candidates" component={Myaccfacilities} /> */}
        <MyaccountRoute
          path="/js-account/settings/change-logins"
          component={MyaccResetLogin}
        />
        <MyaccountRoute
          path="/js-account/settings/delete-account"
          component={MyaccDeleteAcc}
        />
        <MyaccountRoute
          path="/js-account/settings/notifications"
          component={MyacctNotifiation}
        />
        <MyaccountRoute
          path="/js-account/settings/contact-us"
          component={MyaccContact}
        />
        <MyaccountRoute
          path="/js-account/settings/feedback"
          component={MyaccFeedback}
        />
        <MyaccountRoute
          path="/js-account/settings/cookies"
          component={MyaccCookies}
        />
        <MyaccountRoute path="/js-account/settings/faq" component={MyaccFAQ} />
        <MyaccountRoute path="/js-account/dkg-services/pricing" component={MyaccServicespricing} />
        <MyaccountRoute path="/js-account/dkg-services/testimonial" component={MyaccServicestestiomonial} />
        <MyaccountRoute path="/js-account/dkg-services/feedbacks" component={MyaccServicesfeedbacks} />
        <MyaccountRoute path="/js-account/dkg-services/faq-s" component={MyaccServicesfaq} />

        <MyaccountRoute
          path="/js-account/dkg-services/create-cv"
          component={MyaccServicesOverview}
        />
        <MyaccountRoute
          path="/js-account/dkg-services/consultants"
          component={MyaccServicesConsults}
        />
        <MyaccountRoute
          path="/js-account/dkg-services/search-job"
          component={MyaccBenfitService}
        />
        <MyaccountRoute
          path="/js-account/dkg-services/pass-interview"
          component={CommingsoonPage}
        />
        <MyaccountRoute
          path="/js-account/dkg-services/assessment"
          component={CommingsoonPage}
        />
        <MyaccountRoute
          path="/js-account/dkg-services/communication"
          component={CommingsoonPage}
        />
        <MyaccountRoute
          path="/js-account/dkg-services/using-internet"
          component={CommingsoonPage}
        />
        <MyaccountRoute
          path="/js-account/dkg-services/work-abroad"
          component={CommingsoonPage}
        />
        <MyaccountRoute
          path="/js-account/dkg-services/study-abroad"
          component={CommingsoonPage}
        />
        <MyaccountRoute
          path="/js-account/dkg-services/work-abroad"
          component={CommingsoonPage}
        />
        <MyaccountRoute
          path="/js-account/dkg-services/study-abroad"
          component={CommingsoonPage}
        />
        <MyaccountRoute
          path="/js-account/dkg-services/plan-carrer"
          component={CommingsoonPage}
        />
        <MyaccountRoute
          path="/js-account/emp-model/crm-license"
          component={MyaccCRMLinesense}
        />
        <MyaccountRoute
          path="/js-account/emp-model/calls-sms"
          component={MyaccCallSms}
        />
        <MyaccountRoute
          path="/js-account/emp-model/ms-office"
          component={MyaccMSoffice}
        />
        <MyaccountRoute
          path="/js-account/emp-model/ms-mailbox"
          component={MyaccMSMailbox}
        />
        <MyaccountRoute
          path="/js-account/job-profile"
          component={MyaccJobProfile}
        />
        <MyaccountRoute path="/js-account/payments" component={MyaccPayment} />
        <MyaccountRoute path="/js-account/invoices" component={MyaccInvoice} />
        <MyaccountRoute
          path="/js-account/contracts"
          component={MyaccContract}
        />
        <MyaccountRoute path="/js-account/kpi" component={MyaccKpi} />
        <MyaccountRoute
          path="/js-account/interviews"
          component={MyaccInterview}
        />
        <MyaccountRoute
          path="/js-account/job-offers"
          component={MyaccJoboffer}
        />
        <MyaccountRoute path="/js-account/offers" component={MyaccReferrals} />
        <MyaccountRoute path="/js-account/leaves" component={MyaccMyresume} />
        <MyaccountRoute
          path="/js-account/my-profile/summary"
          component={MyaccProfileSuMM}
        />
        <MyaccountRoute
          path="/js-account/my-profile/account-info"
          component={MyaccProfileAccInfo}
        />
        <MyaccountRoute
          path="/js-account/my-profile/contact-info"
          component={MyaccProfileContactInfo}
        />
        <MyaccountRoute
          path="/js-account/my-profile/contact"
          component={MyaccProfileContact}
        />
        <MyaccountRoute
          path="/js-account/dkg-services/summary"
          component={MyaccPaidservover}
        />
        <MyaccountRoute
          path="/js-account/my-profile/profile"
          component={MyaccMyProfile}
        />
        <MyaccountRoute
          path="/js-account/my-profile/resume"
          component={MyaccResume}
        />
        <MyaccountRoute
          path="/js-account/my-profile/cover-letter"
          component={MyaccCoverLetter}
        />
        <MyaccountRoute
          path="/js-account/my-profile/upload-cv"
          component={MyaccUploadCv}
        />
        <MyaccountRoute
          path="/js-account/my-profile/download-cv"
          component={MyaccDownloadCv}
        />
        <MyaccountRoute
          path="/js-account/my-profile/my-documents"
          component={MyaccDocuments}
        />
        <MyaccountRoute
          path="/js-account/my-profile/my-referrals"
          component={MyaccMyReferrals}
        />
      </Switch>
    </div>
  );
}

export default App;
